@import "default-variables";

.skeleton-loader {
    display: none;

    @at-root app-root:empty + & {
        display: block;
        @include site-background;
        flex-direction: column;
    }
    &.skeleton-loader--active {
        display: block;
        @include site-background;
        flex-direction: column;
    }

    .skeleton-grid {
        @include layout-grid;
    }
    
    .skeleton-block {
        @include skeleton-block;
    }

    .header header .mat-toolbar {
        display: flex;
        align-items: center;
    }

    .skeleton-logo {
        &:empty {
            @include skeleton-block;
            width: 150px;
        }
    }

    .header header nav {
        display: none;

        @media screen and (min-width: #{$landscape-tablet}) {
            display: flex;
        }

        .skeleton-anchor {
            @include skeleton-block;
        }
    }

    main {
        margin-top: $mobile-header-height;
        height: calc(100% - #{$mobile-header-height});
        width: 100%;

        @media screen and (min-width: #{$portrait-tablet}){
            margin-top: $header-height;
            height: calc(100% - #{$header-height});
        }
    }
}

.skeleton-content {
    display: none;

    @at-root main &:first-child {
        display: block;
    }
}