@import "default-variables";


html, body {
    margin: 0px;
    width: 100vw;
    height: 100vh;
}

*{
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body{
    overflow: hidden;
}

.app-status, .sr-only {
    position: absolute;
    left: -999999px;
}

.skip-to-main-content {
    height: 30px;
    left: 50%;
    padding: 8px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index: 10000;

    &:focus {
        transform: translateY(0%) translateX(-50%);
    }
}

#main-content-focus {
    outline: none;
}

.debug {
    &:before, &:after {
        display: flex;
        align-content: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        width: 200px;
        height: 20px;
        padding: 5px 0;
        background: rgba(map-get($teller-online--dark-palette, 800), 0.87);
        color: map-get(map-get($teller-online--dark-palette, contrast), 800);
        z-index: 1000;
    }

    &:before {
        left: 0;
        border-top-right-radius: 5px;

        //TODO: (PROD-185)
        @media (max-width: #{$desktop - 1}) {
            content: "Tablet: >= #{$mobile}  < #{$desktop}";
        }

        //TODO: (PROD-185)
        @media (max-width: #{$mobile}) {
            content: "Mobile: < #{$mobile}";
        }

        @media (min-width: #{$desktop}) {
            content: "Desktop: >= #{$desktop}";
        }

        @media (min-width: #{$large-desktop}) {
            content: "Large Desktop: >= #{$large-desktop}";
        }
    }

    &:after {
        right: 0;
        border-top-left-radius: 5px;
        content: "Debug Enabled";
    }
}

a {
    text-decoration: none;
    // Not using the palette because we explicitly want this to always be teller blue, not client specific
    color: $teller-online--primary-blue;

    &:hover {
        cursor: pointer;

        &:not(.mat-button):not(.mat-icon-button):not(.mat-flat-button):not(.mat-menu-item){
            text-decoration: underline;
        }
    }
}

.layout-grid {
    @include layout-grid;

    // TODO: This is default behaviour but is not intended to be the end solution necessarily (PROD-159)
    .main-content {
        grid-column-end: -1;
        grid-column-start: 1;
    }
}

.rail-layout-grid {
    @include rail-layout-grid;

    margin: 16px;

    // nested, make fit full width and only apply the columns to children
    .rail-layout-grid {
        grid-column: 1 / -1;
        @include remove-layout-grid-margins(100%);
    }
}


main article {
    .banner {
        padding: 16px 0;
        margin-bottom: 32px;
        width: 100%;
        color: var(--banner-fg);
        background-color: var(--banner-bg);
        min-height: 80px;
         h1 {
            grid-column-start: 1;
            grid-column-end: -1;
        }
        .layout-grid :last-child {
            margin-bottom: 0;
        }
        p {
            grid-column: 1 / -1;

            @media screen and (min-width: #{$desktop}) {
                grid-column: 1 / span 9;
            }

            @at-root .main-layout--cart main article .banner p {
                @media screen and (min-width: #{$desktop}) {
                    grid-column: 1 / -1;
                }

                @media screen and (min-width: #{$large-desktop}) {
                    grid-column: 1 / span 9;
                }
            }
        }
        a {
            color: var(--banner-link);

            &:hover {
                color: var(--banner-link-hover);
            }
        }
    }
}

.navigation {
    .navigation-link, .navigation-group, .navigation-group.mat-expansion-panel-header {
        &:not(.mat-accent) {
            color: map-get(map-get($teller-online--primary-palette, contrast), 50);

            // Ensure icon color
            path {
                fill: currentColor;
            }

            &.navigation-link--active, &.navigation-link--active:active,
            &.navigation-group--active, &.navigation-group--active:active {
                background-color: rgba(map-get($teller-online--primary-palette, 50), 0.5);
            }

            @at-root .navigation .mat-expansion-panel:not(.mat-expanded) .navigation-group.mat-expansion-panel-header:hover,
            &:hover, &:focus {
                background-color: map-get($teller-online--primary-palette, 50);
            }
        }
    }
}

.narrow-page {
    & &__greeting {
        h1, h2, p {
            @include narrow-layout-grid();
        }
        // these styles are normally applied by angular material by default to h2,
        // but I want it applied to the h1 in this block as well so I copied the full style
        h1, h2 {
            font: 500 20px/32px Roboto,Helvetica Neue,sans-serif;
            letter-spacing: normal;
            margin: 0 0 16px;
        }
    }

    .banner .layout-grid {
        h1 {
            @include narrow-layout-grid();
        }
    }

    form {
        .mat-form-field, a, p, button.not-btn {
            @include narrow-layout-grid();
        }

        button:not(.not-btn) {
            @include narrow-button-layout-grid();
        }

        .password {
            margin-bottom: 60px;
        }

        button[type=submit],
        button + a, button + p  {
            margin-top: 24px;
        }
    }

    app-code-verification{
        // layout will be controlled at the form level
        grid-column: 1 / -1;

        form {
            @include layout-grid();

            button[type=submit] {
                @include narrow-button-layout-grid();
            }
        }
        // done as a separate style becaue it needs to be more specific than the form tag above
        &.code-verification form {
            @include remove-layout-grid-margins();
        }
    }
}

// Style the merchant logos svgIcon the same as the teller-online-icon would be
.mat-icon.teller-online-icon-merchant-logos {
    svg {height: 100%; width: auto}

    &.teller-online-icon--mastercard .mastercard { transform: scale(2) translate(-90px, -19px); }
    &.teller-online-icon--mastercard .discover, &.teller-online-icon--mastercard .amex, &.teller-online-icon--mastercard .visa { display: none;}

    &.teller-online-icon--discover .discover { transform: scale(2) translate(-230px, -19px);}
    &.teller-online-icon--discover .mastercard, &.teller-online-icon--discover .amex, &.teller-online-icon--discover .visa { display: none;}

    &.teller-online-icon--visa .visa { transform: scale(2) translate(-159px, -19px);}
    &.teller-online-icon--visa .discover, &.teller-online-icon--visa .mastercard, &.teller-online-icon--visa .amex { display: none;}

    &.teller-online-icon--amex .amex { transform: scale(2) translate(-17px, -19px);}
    &.teller-online-icon--amex .discover, &.teller-online-icon--amex .mastercard, &.teller-online-icon--amex .visa { display: none;}
}

// Style the cancel svgIcon same as the teller-online-icon would be
.mat-icon.teller-online-icon-cancel {
    svg { transform: scale(1.5); }
}

// Styles to set the color of text to reflect the status of a payment
.pending {
    color: map-get($teller-online--caution-palette, 700);
}

.finalized {
    color: map-get($teller-online--success-palette, 600);
}

.refunded, .cancelled, .posting-failed, .processing-failed {
    color: map-get($teller-online--warn-palette, 600);
}


// Styles expect the table to be using either layout-grid or rail-layout-grid for styling
// and to be accompanied by a list view for mobile
.mat-table {
    display: none;

    @media screen and (min-width: #{$landscape-tablet}) {
        display: table; // remove the display grid that is applied due to layout-grid
                        // layout will be handled by the children
    }

    tr.rail-layout-grid, tr.layout-grid {
        @include remove-layout-grid-margins;

        // changed to min height instead of height to ensure that rows can expand if need be
        height: auto;
        min-height: 46px;

        td, th {
            display: flex;
            align-items: center;

            &.grid-column-multiline:not(.mat-header-cell), &.grid-column-multiline .mat-sort-header-content {
                flex-direction: column;
                text-align: left;
                justify-content: center; // same as align-items: center for direction=row

                .mat-line + .mat-line {
                    font-size: 0.8em;
                }
            }

            .grid-column-ellipsis {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            span {
                display: block;
                width: 100%;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        .grid-column-numeric {
            justify-content: right;
            text-align: right;
        }
    }

    & &__clickable-cell {
        cursor: pointer;
    }
}

// our own component/class, just named "mat-table" to connect it to the table
.mat-table-no-results {
    background: white;

    @at-root :host.payment-history .layout-grid .payment-history__no-results, &.layout-grid span, &.rail-layout-grid span  {
        display: block;
        padding: 16px;
        // text-align: center;

        grid-column: 1 / -1;
    }
}

.mat-icon.mat-icon--search {
    width: 16px;
    height: 16px;
    position: relative;
    top: 5px;

    &[matprefix] {
        left: -3px;
        margin-right: 8px;
    }
    &[matsuffix] {
        right: -3px;
        margin-left: 8px;
    }
}

.mat-button-base .mat-spinner {
    margin: 8px;
}