@import "default-variables";

@mixin load-panel-active {
    z-index: 3000;

    .load-panel__content {
        display: flex;
        p {
            outline: none !important;
        }
    }
}

// these styles are also used by the skeleton loader when the page is
// initially loaded, so it cannot have the :host binding
.load-panel {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    justify-content: center;
    align-items: center;
    display: flex;

    & &__content {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: none;
        height: 100%;
        width: 100%;
        position: relative;

        background: white;
        box-shadow: 0 6px 12px rgb(0 0 0 / 25%);
        border-radius: 2px;
        width: 250px;
        height: 125px;

        p {
            margin-top: 16px;
            margin-bottom: 0;
            font-size: 16px;
            text-align: center;
        }
    }

    & &__overlay {
        background: rgba(map-get($teller-online--grey-palette, 900), 0.4);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.load-panel--active {
        @include load-panel-active;
    }
}
