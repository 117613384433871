/*-------------------------------
------------ Colors -------------
---------------------------------*/
:root {
  --primary-color--900: #1939c1;
  --primary-color: #035ce0;
  --primary-color--700: #006ef3;
  --primary-color--600: #0081ff;
  --primary-color--500: #0090ff;
  --primary-color--400: #00a0ff;
  --primary-color--300: #4db2ff;
  --primary-color--200: #87c8ff;
  --primary-color--100: #b8ddff;
  --primary-color--50: #e2f2ff;
  --primary-color--900-contrast: white;
  --primary-color-contrast: white;
  --primary-color--700-contrast: white;
  --primary-color--600-contrast: white;
  --primary-color--500-contrast: white;
  --primary-color--400-contrast: white;
  --primary-color--300-contrast: #2a3b66;
  --primary-color--200-contrast: #2a3b66;
  --primary-color--100-contrast: #2a3b66;
  --primary-color--50-contrast: #2a3b66;
}

/*-------------------------------
------------- Icons -------------
---------------------------------*/
:root {
  --banner-bg: #2a3b66;
  --banner-fg: white;
  --banner-link: var(--primary-color--300);
  --banner-link-hover: var(--primary-color--200);
}

.header {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: white;
}
.header header .mat-toolbar {
  background-color: transparent;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
  justify-content: space-between;
}
.header header .header__logo {
  height: 48px;
}
@media screen and (min-width: 600px) {
  .header header .header__logo {
    height: 56px;
  }
}
@media screen and (min-width: 905px) {
  .header header .header__logo {
    margin-right: auto;
  }
}
.header header .header__logo img {
  height: 100%;
}
.header header nav {
  display: flex;
  column-gap: 16px;
}