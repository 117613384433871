@import "../libraries/core/src/lib/components/load-panel/load-panel.component.scss";

.appInitLoader {
    display: none;
}
app-root:empty ~ .appInitLoader {
    display: flex;

    @include load-panel-active;
}

app-root:empty ~ .appInitLoader .mat-spinner {
    height: 32px;
    width: 32px;
    position: relative;
    display: block;

    span {
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;
        border-color: map-get($teller-online--primary-palette, 800) map-get($teller-online--primary-palette, 800) transparent;
        border-width: 3px;
        border-style: solid;
        border-radius: 60px;
        position: relative;
        z-index: 5;
        top: 50%;
        left: 50%;
        animation: rotation 973ms;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}

@keyframes rotation {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(359deg);
    }
}