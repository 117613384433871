@import "default-variables";
@import "style-guide";


// in the public portal the margin is applied to the bottom of the banner but there's no banner in 
// admin portal so apply it to the top of the article instead
main article, main article.layout-grid {
    margin-top: 32px;
}

.app-container.app-container--rail .layout-grid {
    @include rail-layout-grid;
    max-width: unset;
}

.app-container.app-container--rail .header .layout-grid {
    //override the left margin for the header to ensure it lines up at the edge of the content (excluding the navigation)
    @media screen and (min-width: #{$landscape-tablet}) {
        margin-left: calc(16px + $rail-width); //existing 16px + navigation width
        width: calc(100% - 32px - $rail-width); //existing 16px margin (both sides) + nav width
    }

    @media screen and (min-width: #{$large-desktop}) {
        margin-left: calc(32px + $rail-width); //existing 32px + navigation width
        width: calc(100% - 64px - $rail-width); //existing 32px margin (both sides) + nav width
    } 
}


.action-bar, .filter-bar {
    display: flex;
    margin-bottom: 16px;

    a, button:not(:last-child) {
        margin-right: 8px;
    }
}

.filter-bar {
    .mat-chip-list-wrapper {
        margin: -2px;
    }
}

.action-bar {
    margin-top: 16px;
}

.rail-layout-grid {
    .filter-bar, .action-bar {
        grid-column: 1 / -1;
    }

    .filter-bar {
        grid-row-start: 2;
    }
    
    .search-field {
        grid-column: 1 / span 4;
        grid-row-start: 1;
    }
}

.mat-table-narrow-warning {
    padding: 8px;
    background: white;
}

.unverified-email {
    display: flex;
    align-items: center;
    
    :first-child {
        margin-right: 8px;
    }

    > :not(.mat-icon) {
        width: auto;
    }
}

.mat-table--loading-enabled {
    position: relative;

    .mat-table-loader-row {
        height: 0;
    }
    .mat-table-loader {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: calc(100% + 56px); //add the height of the paginator since it's not technically part of the table
        z-index: 1;

        &:not(:empty) {
            // add a slightly grey backdrop to simulate the idea that you can't interact with it
            background: rgba(255, 255, 255, 0.4);
        }
        // ensure it's not blocking anything when it's not loaded
        &:empty {
            pointer-events: none;
        }

        .mat-spinner {
            top: calc(50%); // 50% to center it vertically
            left: 50%;      // 50% to center it horizontally
            transform: translate(-50%, -50%); // shift by the width of the element to properly center it
        }
    }
}